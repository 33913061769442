import axios from '@/config/httpConfig'

/**
 * 请求获取用户信息
 * @param {data} 请求参数 
 * @param {enforce} 是否强制请求最新用户信息 
 * @returns 用户信息
 */
export function getUserInfo(data, enforce = false) {
    if (enforce || localStorage.getItem("userInformation") === null) {
        return axios.get('/user/getUserInfo', data)
    } else {
        return new Promise(function (resolve, ) {
            resolve(JSON.parse(localStorage.getItem("userInformation"))); // 回调成功返回
        })
    }
}

// 提交待审批
export function submitApproval(args) {
    return axios.post('approval/submitApproval', args)
}
// 调回审核
export function reCall(args) {
    return axios.post('approval/reCallApproval', args)
}
//文件-附件添加
export function Bind(data) {
    return axios.post('/file/bind', data)
}
// 获取审批预览
export function getApprovalPre(args) {
    return axios.post('approval/getGotoApprovalPreview', args)
}
// 获取审批类型
export function getApprovalType(args) {
    return axios.post('approval/getApprovalObjectList', args)
}
// 获取审批列表
export function getApprovalList(param) {
    return axios.post('approval/getAllApprovalHistoryPendingList', param)
}
//获取审批编辑页面信息
export function getProcessInstanceWorkitem(param) {
    return axios.post('approval/getProcessInstanceWorkitem', param)
}
//获取用户列表
export function getUserList() {
    return axios.post('approval/getUserList')
}
//编辑审批
export function edictApproval(param) {
    return axios.post('approval/updateApprovalProcessOperation', param)
}
//编辑拒绝审批
export function rejectEdictApproval(param) {
    return axios.post('approval/getRejectedInstanceWorkitem', param)
}
// 普通审批与分配
export function commonApproval(param) {
    return axios.post('approval/doApprovalProcessOperation', param)
}
// 批量审批与分配
export function batchApproval(param) {
    return axios.post('approval/batchApprovalProcessOperation', param)
}
// 选取下一个审批人人员列表
export function getLookupData(param) {
    return axios.post('lookup/getLookupData', param)
}
//获取审批相关列表
export function queryApprovalRelatedList(param) {
    return axios.post('approval/queryApprovalRelatedList', param)
}
export function getApprovalHistoryList(param) {
    return axios.post('approval/getApprovalHistoryList', param)
}


// 获取对象权限
export function getObjectPermission(data) {
    return axios.post('objectdetail/getObjectPermission', data)
}

//获取对象记录权限
export function getPermissionById(data) {
    return axios.post('/objectdetail/getPermissionById', data)
}

// 获取选项列表值
export function getSelectValue(data) {
    return axios.post('/view/getSelectValue', data)
}

// 查找带值
export function getLookupRelatedFieldValue(data) {
    return axios.post('/lookup/getLookupRelatedFieldValue', data)
}

// 获取新建事件相关项
export function getDialigItem(data) {
    return axios.post('activity/getQuickActivityPage', data)
}

//获取文件大小限制
export function queryLimit(data) {
    return axios.post('/file/queryLimit', data)
}

//保存加签
export function approvalSave(data) {
    return axios.post('/approval/doInsertApproval', data)
}
//查找用户
export function lookupUser(data) {
    return axios.post('/lookup/getLookupData', data)
}
