<template>
  <div class="batch-list">
    <div class="line"></div>
    <div class="batch-list">
      <div class="batch-title">
        <!-- 待分配项目 -->
        {{$t('label.approval.ressign.project')}}
      </div>
      <div class="table">
        <div class="table-title">
          <div class="item" v-for="(item,index) in tableTitle" :key="index">{{item}}</div>
        </div>
        <div class="table-title table-body" v-for="(item,index) in tableBody" :key="index">
          <div class="item">{{item.objname}}</div>
          <div class="item">{{item.objtype}}</div>
          <div class="item">{{item.actor}}</div>
          <div class="item">{{item.actdate}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      butchValue: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        // tableTitle: ["相关项", "类型", "提交人", "提交时间"],
        tableTitle: [this.$i18n.t('component_telerecord_label_related'), this.$i18n.t('label.type'), this.$i18n.t('label.forecast.forehistory.submitor'), this.$i18n.t('label.refertime')],
      };
    },
    computed: {
      tableBody() {
        return this.butchValue;
      },
    },
  };
</script>

<style lang='scss'>
  .batch-list {
    .line {
      width: 100%;
      height: 10px;
      background: #f8f8f8;
    }
    .batch-list {
      padding: 10px;
      box-sizing: border-box;
      .batch-title {
        font-size: 14px;
        font-weight: 500;
        color: #222222;
        margin-bottom: 10px;
      }
      .table {
        width: 100%;
        border: 1px solid #dddbda;
        border-bottom: none;

        .table-title {
          width: 100%;
          height: 25px;
          background: #f8f8f8;
          border-bottom: 1px solid #dddbda;
          line-height: 25px;
          font-size: 12px;
          .item {
            width: calc(100% / 4 - 10px);
            float: left;
            margin-left: 10px;
          }
        }
        .table-body {
          background: #fff;
        }
      }
    }
  }
</style>