<template>
  <div class="distribution-again">
    <div class="distribution-body">
      <div class="distribution-title">
        <!-- 批量批准/拒绝批准请求 -->
        {{ $t("label.batchapproveorrefuse") }}
      </div>
      <div class="distribution-body">
        <div class="title">
          <!-- 批量批准/拒绝批准请求 -->
          {{ $t("label.batchapproveorrefuse") }}
        </div>
        <div class="body">
          <el-form
            :model="formData"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item>
              <el-button
                @click="submitForm"
                class="left"
                :loading="approvalLoading"
              >
                <!-- 批准 -->
                {{ $t("label.approve") }}
              </el-button>
              <el-button @click="refuse" :loading="approvalLoading">
                <!-- 拒绝 -->
                {{ $t("label.refuse") }}
              </el-button>
              <el-button @click="cancel" :loading="approvalLoading">
                <!-- 取消 -->
                {{ $t("label.cancel") }}
              </el-button>
            </el-form-item>
            <!-- 备注 -->
            <el-form-item :label="$t('label.description')">
              <el-input type="textarea" v-model="formData.remarks"></el-input>
              <div class="select-people">
                <!-- 您所选择的批准请求中有一个或多个要求指定下一个批准人，使用下面的查找框指定下一个批准人 -->
                {{ $t("label.conditionsofnextapprover") }}
              </div>
            </el-form-item>
            <!-- <el-form-item label="批准人"> -->
            <el-form-item :label="$t('label.approver')">
              <el-input v-model="formData.people"></el-input>
              <span class="search-button" @click="searchPeo"
                >(
                <!-- 查找 -->
                {{ $t("label.ems.lookup2") }}
                )</span
              >
            </el-form-item>
            <el-form-item>
              <el-button
                @click="submitForm"
                class="left"
                :loading="approvalLoading"
                ><!-- 批准 -->
                {{ $t("label.approve") }}</el-button
              >
              <el-button @click="refuse" :loading="approvalLoading"
                ><!-- 拒绝 -->
                {{ $t("label.refuse") }}</el-button
              >
              <el-button @click="cancel" :loading="approvalLoading"
                ><!-- 取消 -->
                {{ $t("label.cancel") }}</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <batch-list :butchValue="batchValue"></batch-list>
      </div>
    </div>
    <div class="select-next-approval">
      <!-- <el-dialog title="查找" :visible.sync="isSearch" width="700px"> -->
      <el-dialog
        :title="$t('label.ems.lookup2')"
        :visible.sync="isSearch"
        width="700px"
      >
        <select-next-approval
          @changeSearch="changeSearch"
        ></select-next-approval>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import selectNextApproval from "@/views/approval/components/select-next-approval.vue";
import batchList from "@/views/approval/components/batch-list.vue";
import { batchApproval } from "./api.js";

export default {
  name: "distribution-again",
  data() {
    return {
      approvalLoading: false,
      isSearch: false,
      formData: {
        people: "",
        remarks: "",
      },
      //批量审批获取到的值
      batchValue: JSON.parse(this.$route.query.value),
      //提交的类型
      submitType: "",
    };
  },
  computed: {
    workItemId() {
      let arr = this.batchValue.map((res) => {
        return res.workItemid;
      });
      arr = arr.join(";");
      return arr;
    },
  },
  components: {
    selectNextApproval,
    batchList,
  },
  mounted() {
    if (this.$route.path == "/approvalCenter/batchApproval") {
      document.title = this.$setTitle(this.$i18n.t("label.accraditation"));
    }
  },
  methods: {
    async batchApprovalRequest() {
      let option = {
        comments: this.formData.remarks,
        workItemId: this.workItemId,
        actionType: this.submitType,
        fprId: this.fprId,
      };
      try {
        let result = await batchApproval(option);
        if (result.returnInfo == "Success!") {
          this.approvalLoading = false;
          this.$router.push("/approvalCenter");
          this.$Bus.$emit("cancel-not-loading", true);
        } else if (result.returnInfo == "Manual") {
          this.approvalLoading = false;
          // this.$router.push({
          //   path: "/approvalCenter/lastApprovalPeo",
          //   query: { option: option },
          // });
          this.$router.push("/approvalCenter");
        } else {
          this.approvalLoading = false;
          this.$message({
            showClose: true,
            type: "warning",
            message: result.stackTrace || result.returnInfo,
          });
          this.$router.push("/approvalCenter");
          this.$Bus.$emit("cancel-not-loading", true);
        }
      } catch {
        this.approvalLoading = false;
        this.$router.push("/approvalCenter");
      }
    },

    //收起弹出框
    changeSearch(i, id, name) {
      this.isSearch = false;
      this.fprId = id;
      this.formData.people = name;
    },
    //查找批准人
    searchPeo() {
      this.isSearch = true;
    },
    //批准请求
    submitForm() {
      this.approvalLoading = true;
      this.submitType = "Approved";
      this.batchApprovalRequest();
    },
    //取消请求
    cancel() {
      this.$router.push("/approvalCenter");
    },
    //拒绝请求
    refuse() {
      this.approvalLoading = true;
      this.submitType = "Rejected";
      this.batchApprovalRequest();
    },
  },
};
</script>

<style lang='scss' scoped>
.distribution-again {
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow: auto;
  .distribution-body {
    width: 100%;
    .distribution-title {
      height: 49px;
      font-size: 18px;
      background: #f8f8f8;
      font-weight: 500;
      color: #222222;
      line-height: 49px;
      padding-left: 20px;
    }
    .distribution-body {
      .title {
        margin-top: 14px;
        margin-left: 20px;
        font-size: 14px;
        color: #222;
      }
      .body {
        width: 70%;
        margin: 20px auto;
        .el-form {
          .select-people {
            font-size: 12px;
            font-weight: 400;
            color: #333333;
          }
          .search-button {
            cursor: pointer;
            color: #006dcc;
            font-size: 12px;
            margin-left: 20px;
          }
          .is-have {
            font-size: 12px;
            margin-left: 100px;
          }
          .el-input {
            width: 216px;
          }
          .el-form-item {
            margin-left: calc(50% - 270px);
          }
          .el-form-item__label {
            font-size: 12px;
          }
          .el-input__inner {
            width: 216px;
            height: 30px;
            line-height: 30px;
          }
          .el-textarea {
            width: 531px;
          }
          .el-textarea__inner {
            height: 163px;
            resize: none;
          }
          .batch-button {
            cursor: pointer;
            display: inline-block;
            width: 50px;
            margin-right: 15px;
            height: 25px;
            line-height: 25px;
            text-align: center;
            color: #006dcc;
            font-size: 12px;
            border-radius: 3px;
            border: 1px solid #dddbda;
            font-weight: 400;
          }
        }
      }
    }
  }
  .el-button {
    padding: 6px 15px;
    color: #006dcc;
    font-size: 12px;
  }
}
</style>