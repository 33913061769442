<template>
  <div>
    <div class="function-button">
      <div class="input-kuang">
        <span><!--名称-->{{ $t("label.name") }}</span
        ><input type="text" v-model="searchName.value" @keyup.enter="searchList"/>
      </div>
      <div class="input-kuang">
        <span
          ><!--手机-->
          {{ $t("label.mobile") }} </span
        ><input type="text" v-model="searchPhone.value" @keyup.enter="searchList"/>
      </div>
      <div class="button" @click="searchList">
        <!--搜索-->
        {{ $t("label.searchs") }}
      </div>
      <div class="button clear" @click="clearSearchValue">
        <!--清空-->{{ $t("label.empty") }}
      </div>
    </div>
    <div class="record-count">
      <!-- 共{{ $t("label.multilang.totalpage2") }}{{ tableList.length
      }}条记录
      {{ $t("label.mr.descreption.oneGroupNote3") }} -->
    </div>
    <div
      class="record-list"
      v-infinite-scroll="load"
      infinite-scroll-immediate="false"
    >
      <div class="line title">
        <div class="item"><!--名称-->{{ $t("label.name") }}</div>
        <div class="item">
          <!--手机-->
          {{ $t("label.mobile") }}
        </div>
        <div class="item"><!--电子邮件-->{{ $t("label.email") }}</div>
      </div>
      <div
        class="line"
        v-for="(item, index) in tableList"
        :key="item.id"
        :style="{
          borderBottom:
            index == tableList.length - 1 ? 'none' : '1px solid #dddbda',
        }"
      >
        <div class="item user" @click="submitSelect(item.id, item.name)">
          {{ item.name }}
        </div>
        <div class="item">{{ item.mobile }}</div>
        <div class="item">{{ item.email }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getLookupData } from "./api.js";
export default {
  data() {
    return {
      dialogVisible: true,
      recordCount: 5,
      searchName: { value: "", fieldId: "" },
      searchPhone: { value: "", fieldId: "" },
      tableList: [],
      pageNumber: 1,
      pageSize: 10,
    };
  },
  computed: {
    searchConditions() {
      let fieldIdName = this.searchName.fieldId;
      let fieldIdPhone = this.searchPhone.fieldId;
      let obj = {};
      obj[fieldIdName] = this.searchName.value;
      obj[fieldIdPhone] = this.searchPhone.value;
      return fieldIdName == "" && fieldIdPhone == "" ? "" : JSON.stringify(obj);
    },
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    //选中批准人
    submitSelect(id, name) {
      this.$emit("changeSearch", false, id, name);
    },
    //清空
    clearSearchValue() {
      this.searchName.value = "";
      this.searchPhone.value = "";
      this.tableList = [];
      this.pageNumber = 1;
      this.getDataList();
      //清除下个审批人输入框中已选审批人
      this.$Bus.$emit('clear-next-approvalPeople')
    },
    //搜索
    searchList() {
      this.tableList = [];
      this.pageNumber = 1;
      this.getDataList();
    },
    load() {
      this.pageNumber++;
      this.getDataList();
    },
    async getDataList() {
      let option = {
        prefix: "005",
        objId: "user",
        isSLookup: "true",
        page: this.pageNumber,
        pageSize: this.pageSize,
        searchConditions: this.searchConditions,
      };
      let result = await getLookupData(option);
      if (result.result == true && result.data.dataList) {
        // if (result.data.dataList.length > 0) {
        //   this.recordCount = result.data.totalCount;
        // }
        this.tableList = this.tableList.concat(result.data.dataList);
        // let ownerId = this.tableList.findIndex(
        //   (res) => res.id == localStorage.getItem("myid")
        // );
        // if (ownerId >= 0) {
        //   this.tableList.splice(ownerId, 1);
        // }
        this.searchName.fieldId = result.data.headLabel[0].id;
        this.searchPhone.fieldId = result.data.headLabel[1].id;
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.select-next-approval {
  .el-dialog {
    .function-button {
      overflow: hidden;
      .input-kuang {
        float: left;
        margin-right: 30px;

        span {
          margin-right: 30px;
        }
        input {
          outline: none;
          width: 170px;
          height: 30px;
          border-radius: 3px;
          border: 1px solid #dddbda;
        }
      }
      .button {
        cursor: pointer;
        color: #006dcc;
        float: left;
        width: 50px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-radius: 3px;
        border: 1px solid #dddbda;
        margin-right: 10px;
      }
      .clear {
        margin-right: 0;
      }
    }
    .record-count {
      margin: 20px 0;
    }
    .record-list {
      width: 100%;
      border-top: 1px solid #dddbda;
      border-bottom: 1px solid #dddbda;
      max-height: 300px;
      overflow: auto;
      .line {
        font-size: 12px;
        width: 100%;
        height: 30px;
        border-left: 1px solid #dddbda;
        border-bottom: 1px solid #dddbda;
        .item.user {
          color: #006dcc;
          cursor: pointer;
        }
        .item {
          color: #4d5f76;
          width: 33.33%;
          height: 100%;
          line-height: 30px;
          padding-left: 10px;
          border-right: 1px solid #dddbda;
          box-sizing: border-box;
          float: left;
        }
      }
      .line.title {
        background: #f8f8f8;
      }
    }
    .el-dialog__header {
      border-bottom: 2px solid #f3f2f2;
    }
  }
}
</style>